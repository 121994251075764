
/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/users.services.ts" />

class usersCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$window', '$scope', '$location', '$compile', 'socketService', 'toastr', '$http'];
    showsubflows: boolean;
    toastrObj: any;
    genericServices: GenericServiceClass;
    userServices: UsersServiceClass;
    current_user_id: any;
    constructor(
        public $window,
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr,
        public $http,
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'users';
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.userServices = new UsersServiceClass(this.service.socket, this.service.namespace)
        var user: any = this.socketService.user;

        this.basequery = '_type eq \'user\' and username ne \'iRoboadmin\' and username ne \'irobo.super@admin\''; // to hide the super user

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }

        this.socketService.onSignedin(() => {
            this.current_user_id = user._id;
            this.getPagingData();
            //this.loaddata();

        });
    }
    preventWhiteSpaces(event) {

        if (event.originalEvent.which == 32) {

            event.preventDefault();
        }
    }
    upload(file) {
        if (!file) { return; }
        var aReader = new FileReader();
        aReader.readAsText(file, "UTF-8");
        aReader.onload = (evt) => {
            var users: any = [];
            var _content = aReader.result;
            var newline = (_content as any).indexOf(/\r\n/) > -1 ? /\r\n/ : /\n/;
            var csv = (_content as string).split(newline);
            var delimiter = csv[0].indexOf(',') > -1 ? ',' : ';';
            csv.forEach((item) => {
                var line = item.split(delimiter);
                var user = {
                    name: line[0],
                    username: line[1],
                    role: line[2],
                    password: line[3]
                };
                if (user.name != 'name' && user.name != 'navn' && user.name != '' && user.username != '') {
                    users.push(user);
                }
            });
            this.loading = true;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.addusers(users);
        };
        aReader.onerror = (evt) => {
        };
    };

    async deleteUser(userId) {

        let data = { id: userId };

        let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        this.$http.post('/user/delete', data, headers).then(() => {
            // on sucess reload data
            // this.$window.location.reload();
            this.getPagingData();

        }
            ,
            (err) => { console.log(err) });

    }

    async addusers(users) {
        if (users.length == 0) {
            this.loading = false;
            this.entities = [];
            this.page = 1;
            //this.loaddata();
            this.getPagingData();
            return;
        }
        var user = users[0];
        try {

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
        var hash = await this.userServices.hash(user.password);
        user.passwordhash = hash;
        delete user.password;
        user._type = 'user';
        var query = "username eq '" + user.username + "'";
        var items = await this.genericServices.get(query, 1, null, null, null, 'users');
        if (items.length == 0) {
            await this.genericServices.post(user, 'users');
            users.splice(0, 1);
            this.addusers(users);
        }
        else {
            items[0].name = user.name;
            items[0].username = user.username;
            items[0].passwordhash = user.passwordhash;
            await this.genericServices.put(items[0], 'users');
            users.splice(0, 1);
            this.addusers(users);
        }
    };
}

class addUserCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$timeout', '$routeParams', 'toastr'];
    id: string;
    model: any;
    ChangePasswordForm: any;
    loading: boolean = false;
    toastrObj: any;
    DisabledEdit = false;
    DisableEditIsActive = false;
    genericServices: GenericServiceClass;
    userServices: UsersServiceClass;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public toastr
    ) {

        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {
            this.DisabledEdit = false;
            this.DisableEditIsActive = true;
            this.model = {
                name: '',
                username: '',
                password: '',
                _type: 'user',
                isactive: true
            };
        });
    }

    clearFields() {
        this.ChangePasswordForm.newpassword = '';
        this.ChangePasswordForm.confirmpassword = '';
    }

    preventWhiteSpaces(event) {

        if (event.originalEvent.which == 32) {

            event.preventDefault();
        }
    }
    focusfirstinput() {
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = null;
        for (var i = 0; i < data.ui.length; i++) {
            if (data.ui[i].readonly == false) {
                name = data.ui[i].name;
                break;
            }
        }
        if (name) {
            this.$timeout(() => {
                this.$scope.$broadcast(name, null);
            }, 50);
        }
    }
    async AddUser() {
        this.loading = true;
        this.model.isrobot = true; // set the isrobot true to appear in iroboflow
        try {
            var nameQuery = "username eq '" + this.model.username + "'";
            var usernames = await this.genericServices.get(nameQuery, 1, null, null, null, 'users');
            var mailQuery = "email eq '" + this.model.email + "'";
            var emails = await this.genericServices.get(mailQuery, 1, null, null, null, 'users');

            if (usernames.length == 0 && emails.length == 0) {
                await this.userServices.AddUser(this.model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                if (usernames.length != 0) {
                    this.loading = false;
                    this.toastrObj.error("username is already taken");
                }
                if (emails.length != 0) {
                    this.loading = false;
                    this.toastrObj.error("Email is already taken");
                }
            }



        }
        catch (err) {
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
            //handleError(this.$scope, err);
        }
    }

    async update() {
        this.loading = true;
        try {
            if (this.model.password && this.model.password != '') {
                var hash = await this.userServices.hash(this.model.password);
                this.model.passwordhash = hash;
                this.savemodel(this.model);
            } else {
                this.savemodel(this.model);
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async savemodel(model) {
        delete model.password;
        try {
            if (model._id) {
                await this.genericServices.put(model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                await this.genericServices.post(model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async ChangePassword(userObj) {
        try {
            if (this.ChangePasswordForm && this.ChangePasswordForm.newpassword) {
                if ((true || this.ChangePasswordForm.oldpassword) && this.ChangePasswordForm.newpassword && this.ChangePasswordForm.newpassword != '' && this.ChangePasswordForm.newpassword == this.ChangePasswordForm.confirmpassword) {
                    var user = await this.userServices.GetUserToken(this.model.username, this.ChangePasswordForm.oldpassword, this.model._id);
                    if (user && user.token && user._id == this.model._id) {
                        await this.userServices.resetpassword(user.token, this.ChangePasswordForm.newpassword);
                        this.toastrObj.success("Password updated successfuly");
                        $('#ChangePasswordModalCancel').click();
                        //  this.$location.path("/projects");
                    }
                }
            }
        } catch (err) {
            this.toastrObj.error(err.message);
        }
    }
    goback() {
        this.$location.path("/main");
    }

}
class editUserCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$timeout', '$routeParams', 'toastr'];
    id: string;
    model: any;
    ChangePasswordForm: any;
    loading: boolean = false;
    toastrObj: any;
    DisabledEdit = false;
    DisableEditIsActive = false;
    genericServices: GenericServiceClass;
    userServices: UsersServiceClass;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public toastr
    ) {
        this.id = this.$routeParams.id
        this.toastrObj = toastr;
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {

            this.loaddata(this.id);


        });
    }

    clearFields() {
        this.ChangePasswordForm.newpassword = '';
        this.ChangePasswordForm.confirmpassword = '';
    }
    async loaddata(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such user found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such user found ' + id } }));
                return;
            }
            this.model = items[0];
            this.DisabledEdit = false;
            if (this.model.ownerid || this.model.isOwner == true || this.model.CommunityLicense == true) {
                this.DisabledEdit = true;
            }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.focusfirstinput();
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));

        }
    }
    preventWhiteSpaces(event) {

        if (event.originalEvent.which == 32) {

            event.preventDefault();
        }
    }
    focusfirstinput() {
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = null;
        for (var i = 0; i < data.ui.length; i++) {
            if (data.ui[i].readonly == false) {
                name = data.ui[i].name;
                break;
            }
        }
        if (name) {
            this.$timeout(() => {
                this.$scope.$broadcast(name, null);
            }, 50);
        }
    }
    async UpdateUser() {
        this.loading = true;
        this.model.isrobot = true; // set the isrobot true to appear in iroboflow
        try {
            var nameQuery = "username eq '" + this.model.username + "'";
            var usernames = await this.genericServices.get(nameQuery, 1, null, null, null, 'users');
            var mailQuery = "email eq '" + this.model.email + "'";
            var emails = await this.genericServices.get(mailQuery, 1, null, null, null, 'users');

            var userQuery = "_id eq '" + this.model._id + "'";
            var user = await this.genericServices.get(userQuery, 1, null, null, null, 'users');
            if (user[0].username == this.model.username && user[0].email == this.model.email) {
                await this.userServices.UpdateUser(this.model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else if (user[0].username == this.model.username && emails.length == 0) {
                await this.userServices.UpdateUser(this.model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else if (user[0].email == this.model.email && usernames.length == 0) {
                await this.userServices.UpdateUser(this.model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else if (usernames.length == 0 && emails.length == 0) {
                await this.userServices.UpdateUser(this.model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                if (usernames.length != 0 && user[0].username != this.model.username) {
                    this.loading = false;
                    this.toastrObj.error("username is already taken");
                }
                if (emails.length != 0 && user[0].email != this.model.email) {
                    this.loading = false;
                    this.toastrObj.error("Email is already taken");
                }
            }

            return;


        }
        catch (err) {
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
            //handleError(this.$scope, err);
        }
    }

    async update() {
        this.loading = true;
        try {
            if (this.model.password && this.model.password != '') {
                var hash = await this.userServices.hash(this.model.password);
                this.model.passwordhash = hash;
                this.savemodel(this.model);
            } else {
                this.savemodel(this.model);
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async savemodel(model) {
        delete model.password;
        try {
            if (model._id) {
                await this.genericServices.put(model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                await this.genericServices.post(model, 'users');
                this.loading = false;
                this.$location.path("/users");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async ChangePassword(userObj) {
        try {
            if (this.ChangePasswordForm && this.ChangePasswordForm.newpassword) {
                if ((true || this.ChangePasswordForm.oldpassword) && this.ChangePasswordForm.newpassword && this.ChangePasswordForm.newpassword != '' && this.ChangePasswordForm.newpassword == this.ChangePasswordForm.confirmpassword) {
                    var user = await this.userServices.GetUserToken(this.model.username, this.ChangePasswordForm.oldpassword, this.model._id);
                    if (user && user.token && user._id == this.model._id) {
                        await this.userServices.resetpassword(user.token, this.ChangePasswordForm.newpassword);
                        this.toastrObj.success("Password updated successfuly");
                        $('#ChangePasswordModalCancel').click();
                        //  this.$location.path("/projects");
                    }
                }
            }
        } catch (err) {
            this.toastrObj.error(err.message);
        }
    }
    goback() {
        this.$location.path("/main");
    }

}
class changepasswordCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$timeout', '$routeParams', 'toastr'];
    id: string;
    genericServices: GenericServiceClass;
    userServices: UsersServiceClass;
    model: any;
    ChangePasswordForm: any;
    loading: boolean = false;
    toastrObj: any;
    DisabledEdit = false;
    DisableEditIsActive = false;
    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public toastr
    ) {
        this.id = this.$routeParams.id
        this.toastrObj = toastr;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        //  var user = this.socketService.user; 
        // if(!user.roles.find(R=>R._id == adminsRoleId )){
        //     this.$location.path("/") 
        //     this.$scope.$apply();
        // }
        this.socketService.onSignedin((user_data) => {
            this.id = user_data._id;
            this.DisabledEdit = false;
            this.DisableEditIsActive = true;
            this.model = {
                _id: user_data._id,
                name: user_data.name,
                username: user_data.username,
                password: '',
                _type: 'user',
                isactive: true
            };

        });
    }
    async loaddata(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such user found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such user found ' + id } }));
                return;
            }
            this.model = items[0];
            this.DisabledEdit = false;
            if (this.model.ownerid || this.model.isOwner == true || this.model.CommunityLicense == true) {
                this.DisabledEdit = true;
            }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.focusfirstinput();
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));

        }
    }

    focusfirstinput() {
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = null;
        for (var i = 0; i < data.ui.length; i++) {
            if (data.ui[i].readonly == false) {
                name = data.ui[i].name;
                break;
            }
        }
        if (name) {
            this.$timeout(() => {
                this.$scope.$broadcast(name, null);
            }, 50);
        }
    }

    preventWhiteSpaces(event) {

        if (event.originalEvent.which == 32) {

            event.preventDefault();
        }
    }




    async ChangePassword(userObj) {
        try {
            if (this.ChangePasswordForm && this.ChangePasswordForm.newpassword) {
                if ((true || this.ChangePasswordForm.oldpassword) && this.ChangePasswordForm.newpassword && this.ChangePasswordForm.newpassword != '' && this.ChangePasswordForm.newpassword == this.ChangePasswordForm.confirmpassword) {
                    var user = await this.userServices.GetUserToken(this.model.username, this.ChangePasswordForm.oldpassword, this.model._id);
                    if (user && user.token && user._id == this.model._id) {
                        await this.userServices.resetpassword(user.token, this.ChangePasswordForm.newpassword);
                        this.toastrObj.success("Password updated successfuly");
                        // $('#ChangePasswordModalCancel').click();
                        this.$location.path("/projects");
                    }
                }
            }
        } catch (err) {
            this.toastrObj.error(err.message);
        }
    }
    goback() {
        this.$location.path("/main");
    }

}